.bg-teamMange_nav {
	background-color: white;
	color: #313533;
	width: 81px;
	height: 41px;
	font-size: 16px;
	font-family: "Cairo";
}

.icons-teamMange {
	font-size: 1.1rem;
}

.header-icon {
	position: absolute;
}
.collapse-bg {
	background-color: #d6d7dd;
	border: none !important;
	color: #000 !important;
}
.collapse-btn {
	background-color: #9d9d9d;
	border: none !important;
	font-size: 10px;
	width: 44px;
	background-color: #313533;
	height: 47px;
}
.nav-icons__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: flex-end;
}
.nav-icon__header {
	display: flex;
	/* 	flex-direction: column;
 */
	justify-content: center;
	align-items: center;
}
.nav-icon {
	font-size: 30px;
	line-height: 8px;
	color: #000;
}
.nav-icon__notActive {
	font-size: 20px;
	line-height: 9px;
	color: #a7afb7;
}
.cancel-bg {
	font-size: 1rem !important;
	color: #303032 !important;
	background-color: #e9e9ef !important;
	border-radius: 3px;
}
.cancel-bg:hover {
	color: #fff !important;
	background-color: #d4d4dba9 !important;
}
.member-num {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: #313533;
	color: #ffffff;
	text-align: center;
	font-weight: 800;
}
.w-90 {
	width: 90%;
}
.permission-card {
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 1px solid rgb(207, 205, 205);
	border-radius: 5px;
	color: #313533;
	/*  align-items: flex-start; */
	font-weight: 500;
	font-size: 1.3rem;
}

html[dir="rtl"] .required {
	position: absolute;
	z-index: 1;
	color: #ff5f59;
	padding: 15px;
	font-size: 8px;

	left: 0px;
}
html[dir="ltr"] .required {
	position: absolute;
	z-index: 1;
	color: #ff5f59;
	padding: 15px;
	font-size: 8px;
	right: 0px;
}
