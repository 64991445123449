.bg-subNav {
	background-color: #e5e5e5;
	padding: 10px;
	color: #a7afb7;
}

.active-companies-tourism {
	border-top: 2px solid #57c960;
	background-color: #e5e5e5;
	padding: 10px;
	color: #354f5c;
	position: relative;
	&:before {
		content: "";
		transform: rotate(62deg);
		position: absolute;
		bottom: -2px;
		position: absolute;
		left: 44%;
		width: 0px;
		height: 0px;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 8px solid #ffffff;
	}
}
.w-15 {
	width: 15%;
}

.shape-border {
	border: 2px solid #91969b;
	width: 15%;
}

.companies-btn {
	background-color: #354f5c;
	color: white;
	padding: 10px;
	width: 15%;
	border-radius: 0;
}

.collapse-border {
	background-color: #d6d7dd;
	border: 1px solid #a7afb7 !important;
	color: #313533;
	p {
		margin-bottom: 0px !important;
	}
}

.collapse-table-border {
	border: 1px solid #a7afb7 !important;
	border-top: 0 !important;
}

.circly {
	background-color: #354f5c;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	padding: 3px;
	color: white;
}

.file-btn {
	background-color: #00aaeb;
	position: relative;
	border-radius: 5px;
	color: white;
	font-weight: 500;
	border: none;
	width: 10%;
	padding: 3px;
	font-size: 14px;
	box-shadow: #00aaeb 0px 3px 6px;
	cursor: pointer;
}
.custom-file {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;
	top: 0;
}
.bg-information {
	background-color: #c8c8c8;
}
.wallet-box {
	border: 1px solid #d6d7dd;
}
.wallet-btns {
	overflow: hidden !important;
	border: 1px solid #d3d7db;
	.bg-wallet-btn {
		background-color: #cb9a51;
		color: white;
	}
}
.bg-wallet-name {
	background-color: #313533;
	transition: 0.5s;
	color: white;
}

.trash:hover {
	color: rgb(231, 28, 28);
	transition: 0.3s;
	cursor: pointer;
}
.trash {
	font-size: 20px;
	transition: 0.3s;
	color: #a7afb7;
}

.text-gray {
	color: #a7afb7 !important;
}
/** tabs **/
.tabs {
	background-color: #fff !important;

	.bg-subNav {
		padding: 15px 30px;
		border: 1px solid #dfdfdf;
	}
	.bg-subNav.active-companies-tourism {
		background-color: #fff;
		font-weight: bold;
		border-bottom: 1px solid #fff;
	}
	/* .active-companies-tourism:before {
			content: "";
			transform: rotate(180deg);
			position: absolute;
			bottom: -2px;
			position: absolute;
			left: 44%;
			width: 0px;
			height: 0px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 11px solid #ebebeb;
		}
 */
	.tab-content {
		min-height: 84vh;
	}
	.nav {
		display: flex;
		flex-wrap: nowrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		.nav-item {
			min-height: 55px;
			width: 20%;
			text-align: center;
			margin-bottom: 0px !important;
			background: #ebebeb !important;
		}
		.nav-link.active {
			height: 55px !important;
		}
		.nav-link {
			height: 55px !important;
			border: 0.3px solid #dfdfdf;
			border-bottom: 0;
			border-radius: 0 !important;
		}
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-color: #fff;
		min-height: 55px !important;
		background-color: #fff !important;
		border-radius: 0 !important;
		border: 0.5px solid #dfdfdf;
		border-bottom: 0;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active .product-build-list-item span {
		color: #cb9a51 !important;
		font-size: 18px;
		font-weight: 700;
	}

	.tab-icons {
		background-color: #ebebeb !important;
	}

	.no-hotel {
		width: 100%;
		background: #f7f7f7;
		height: 350px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 0.3125rem;
		p {
			color: #979797;
			margin: 15px 0;
		}
	}
	.btn-yallow {
		background-color: #cb9a51 !important;
		color: #fff;
		font-size: 16px;
	}
	.select-hr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			color: #979797;
			font-size: 15px;
		}
		div {
			height: 1px;
			width: 88%;
			background: #dfdfdf;
		}
	}
}
.sub-tabs {
	.nav-link {
		display: flex;
		align-items: center;
		background: #ebebeb;
		color: #222;
		border: 1px solid #ebebeb;
	}
	.nav-link.active {
		color: #fff !important;
		display: flex;
		border-radius: 0 !important;
		align-items: center;
	}
}
.semiHeight {
	height: 35px !important;
}
.view-transaction-popup-model {
	color: #222;
}
