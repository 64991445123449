.login-page {
	// background: url(../../../assets/images/login/login_image.png);
	// background-repeat: no-repeat;
	// background-size: 100% 100%;
	overflow: hidden;
	.login-box {
		width: 25%;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		flex-direction: column;
		background: rgba(251, 251, 251, 0.82);
		.logo-box {
			height: 100px;
			font-weight: bold;
			color: #3c3c3c;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.login-form-box {
			width: 70%;
			.login-text {
				color: #3c3c3c;
				text-align: center;
				margin-bottom: 1rem;
				font-weight: 600;
			}
			form {
				select,
				input {
					padding: 0.7rem 0.75rem;
					height: auto;
					font-size: $text-caption;
					font-weight: 600;
					cursor: pointer;
				}
				.login-btn {
					input {
						background-color: #354f5c;
						color: #fff;
						text-transform: uppercase;
						font-weight: normal;
					}
				}
			}
			.register {
				text-align: center;
				padding: 2rem 0;
				a {
					font-size: $text-caption + 6;
					color: #354f5c;
					font-weight: 600;
				}
			}
			.resend {
				a {
					color: $orangColor;
					font-size: $text-caption + 4;
					font-weight: 600;
				}
			}
		} //login-form-box
	} //login-box
}
