// Directions
$dir: ltr;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

html {
	--right: right;
	--left: left;
	--dir: ltr;
	--productListTranslate: 150%;
	--percentage: 100%;
	--icx-left-solid-arrow: "\e903";
	--icx-right-solid-arrow: "\e904";
	--icx-arrow-right: "\e908";
}

// @import "~bootstrap";

// Base Files
@import "base/variables";
@import "base/reset";
@import "base/functions";
@import "base/mixins";
@import "base/colors";
@import "base/typography";
@import "base/icomoon";
@import "base/general";
@import "base/custom-modal";

// Shared Components
@import "shared/all";

// Modules
@import "./modules/auth/all";
@import "./modules/HotelLayout/all";
@import "./modules/backOffice/all";
@import "./modules/progressCircular/all";
@import "./modules/payment/all";
@import "./modules/visa/all";
@import "./modules/suppliers/all";
@import "./modules/SBSReservations/all";
@import "./modules/b2c/all";
@import "./modules//financial/all";

@import "./modules/onilneVisa/all";
@import "./modules/vendor/all";
@import "./modules/manualSupplier/all";

@import "./modules/backOffice/teamManagement.scss";
@import "./modules/backOffice/sbsReservations.scss";
@import "./modules/backOffice/sidebar";

@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");

@import "./app.scss";
@import "./custom.scss";
@import "./bootstrap.scss";

body,
div,
td {
	font-family: sans-serif, "Cairo" !important;
}

html[dir="rtl"] {
	--dir: rtl;
	--left: right;
	--right: left;
	--icx-left-solid-arrow: "\e904";
	--icx-right-solid-arrow: "\e903";
	--icx-arrow-right: "\e908";
	--productListTranslate: -150%;
	--percentage: -100%;

	body {
		font-family: "Cairo", sans-serif;
	}
}

html[dir="ltr"] {
	body {
		font-family: sans-serif, "Cairo";
	}

	input,
	button,
	select,
	optgroup,
	textarea {
		font-family: sans-serif, "Cairo";
	}
}

.bg-navy {
	background: #324356;
}

.CompanyActions-link {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14px !important;
	color: #354f5c !important;
}

.fa-th-list {
	margin-left: 5px;
}

html[dir="ltr"] .fa-th-list {
	margin-right: 5px;
	margin-left: unset;
}
.is-active {
	background-color: #ffffff !important;
	color: #354f5c !important;
	font-weight: bold;
	border: 1px solid #d1d6dd !important;
	border-radius: 5px 5px 0px 0px;
	border-bottom: none !important;
}
.maxW320 {
	max-width: 320px;
}
.text-danger {
	color: #dc3545 !important;
}

.facilities-content ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-basis: 250px;
	flex-wrap: wrap;
}
.facilities-content ul li {
	background-color: white;
	box-shadow: 0px 1px 5px #74788d;
	padding: 5px;
	border-radius: 5px;
	margin: 2px 0;
}
.marketplace-details .hotel-facilities ul li,
.mybooking-page-wrapper .hotel-facilities ul li {
	background-color: white;
	box-shadow: 0px 1px 5px #74788d;

	border: 1px grey;
	padding: 5px 7px;
	border-radius: 5px;
	margin: 2px 0;
}
.marketplace-details .hotel-facilities ul,
.mybooking-page-wrapper .hotel-facilities ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-basis: 250px;
	flex-wrap: wrap;
}

.activeClass {
	.nav-item {
		color: #e0e9f3 !important;
	}
}
.disabled-li {
	opacity: 0.5;
	cursor: not-allowed;
}

.table-responsive {
	width: 100% !important;
}

.bg-white-blue {
	/* background: #fff !important; */
}

.DateInput {
	background: #fff !important;
}
.DateInput_input {
	background: transparent !important;
}
.SingleDatePicker {
	width: 100% !important;
}
.DateInput_input {
	position: relative;
	z-index: 2;
}
.text-caption {
	margin: 0 !important;
}

.companies-btn {
	color: #fff;
	background-color: #007bff !important;
	border-color: #007bff !important;
}
.companies-btn:hover {
	background-color: #007bffcc !important;
	border-color: #007bffd2 !important;
}

.btn-sbs-primary {
	font-size: 1rem !important;
	color: #ffffff;
	background-color: #1777fb;
	box-shadow: #1777fb 0px 3px 6px;
	border-radius: 3px;
}
.btn-sbs-primary:hover {
	background-color: #007bffd2;
}
.btn-sbs-secondary {
	font-size: 1rem !important;
	color: #303032 !important;
	background-color: #e9e9ef !important;
	border-radius: 3px;
}
.btn-sbs-secondary:hover {
	color: #fff !important;
	background-color: #74788d !important;
}
.form-check-label {
	color: #313533 !important;
}

.fix-text-white {
	.item--key {
		// color: #fff !important;
	}
}

.main-title {
	font-size: 20px;
	font-family: "Cairo";
	font-weight: bold;
	color: #313533;
}

.all-tabs {
	background-color: none !important;
	border-bottom: 1px solid #d1d6dd;
	.our-taps {
		border-radius: 6px 6px 0 0 !important;
		padding: 0.9rem 2rem;
		display: inline-block;
		color: #313533;
		background: #fff;
		border: 1px solid #e5e5e5;
		margin-inline-end: 10px;
		font-size: 14px;
	}
	.is-active {
		font-weight: bold;
		position: relative;
		margin-bottom: -1px;
	}
}
.d-flex.align-items-center.text-secondary.pointer {
	img {
		margin: 0 5px;
	}
}
.route-block {
	border-bottom: 1px solid gray;
}
.our-border {
	border: 1px solid#D1D6DD;
}
.reset-btn {
	width: 115px;
	height: 40px !important;
	background-color: #e9e9ef;
	padding: 0px 10px;
	border-radius: 50px;
	margin-top: 25px !important;
}
.reset-btn:hover {
	background-color: #d5d5db !important;
	box-shadow: #e9e9ef 0px 3px 6px;
}
.filter-col {
	background-color: #74788d;
	color: #e9e9ef;
}
.filter-col:hover {
	background-color: #74788dc7;
	color: #e9e9ef;
}

.room-row {
	padding-right: 5px !important;
}
.room-row .control-field__body {
	padding: 5px 0px !important;
}
.Amenities-collapse {
	background-color: #d6d7dd !important;
	color: #313533 !important;
}
.collapse-box {
	border: 1px solid #d1d6dd !important;
	background-color: #eae8e9 !important;
}
.property .amenity.checked {
	border: 1px solid #eee !important;
}
.pro-label {
	font-size: 16px;
	color: #313533 !important;
	padding: 8px 0px !important;
}
.pro-data {
	font-size: 16px;
	color: #313533 !important;
	font-weight: normal !important	;
}
.per-title {
	border-bottom: 1px solid rgb(207, 205, 205);
}
.booking-box {
	border: 1px solid #eee;
	margin-top: 10px;
	box-shadow: 0px 3px 6px #eee;

	.booking-header {
		border-bottom: 1px solid #eee;
		margin-bottom: 10px;
	}
	.booking-label {
		font-weight: bold;
		color: #000;
	}
}
.bg-room {
	background-color: white !important;
	border-radius: 5px;
	box-shadow: 0px 1px 5px gray;
}
.model-msg {
	width: 75%;
	text-align: center;
}
.bg-gray-white-100 {
	background-color: #ebebeb;
}
.selected-hotels {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	.hotel {
		max-width: 130px;
		padding: 0px 0px 0px 8px;
		border-radius: 20px;
		background-color: #8080804d;
		margin: 3px;
		.hotel-name {
			display: inline-block;
			margin-bottom: -5px;
			text-transform: capitalize !important;
			white-space: nowrap;
			max-width: 100px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.delete-hotel {
			color: #303032;
			padding: 8px 5px;
			z-index: 99;
		}
	}
}
.p-SelectField-0 {
	margin-top: 3px;
	.control-field__body {
		padding-left: 0;
		padding-right: 0;
	}
}
.hotel-display {
	max-width: 130px;
	padding: 0px 0px 0px 8px;
	border-radius: 20px;
	background-color: #fbfaff;
	margin: 3px;
	visibility: hidden !important;
	.hotel-name {
		display: inline-block;
		margin-bottom: -5px;
		text-transform: capitalize !important;
		white-space: nowrap;
		max-width: 100px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.delete-hotel {
		color: #fbfaff;
		padding: 8px 5px;
		z-index: 99;
	}
}

.main-content-close {
	margin-left: 70px !important;
}
.genlink-box {
	border: 1px solid #d1d6dd;
	.genlink-box-head {
		border-bottom: 1px solid #d1d6dd;
		padding: 10px;
	}
}
.room-card {
	border: 1px solid #d1d6dd;
	border-radius: 10px;
	padding: 5px;
	margin: 5px;
}

.FIX_modal_append {
	.control-field__append {
		inset-inline-end: 5px !important;
		inset-block-end: 16px !important;
	}
}
.canceled {
	color: red !important;
}
.definite {
	color: #00b545 !important;
}
.tentative {
	color: #b17a00;
}
.breadcrumb-app {
	background-color: #e6e6e6;
	display: inline-block;
	box-shadow: 3px 3px 3px #efefef;
	border-radius: 5px;
	.breadcrumb {
		padding: 0 !important;
		margin-bottom: 0 !important;
	}
	.breadcrumb-item {
		font-size: 18px;
	}
}

body[data-layout-mode="dark"] .dropdown-menu {
	// background: transparent !important ;
}

.notification {
	.notifaction-items-header {
		left: unset !important;
		right: 0 !important;
	}
	.dropdown-menu {
		padding: 0 !important;
		margin: 0 !important;

		.dropdown-item.DropdownItem:hover {
			background-color: rgba(23, 119, 246, 0.1) !important;
		}
		.notify-show-more.notify-btn:hover {
			background-color: rgba(23, 119, 246, 0.1) !important;
			h6 {
				color: #fff;
			}
		}
	}

	.bell-notifaction:active,
	.bell-notifaction:focus,
	.bell-notifaction:hover {
		background-color: transparent !important;
		border: none !important;
		box-shadow: none !important;
	}
	.bell-notifaction {
		position: relative;
		background-color: transparent;
		border: none !important;
		span {
			position: absolute;
			top: 1px;
			right: 3px;
			background: #fe6c72;
			width: 20px;
			height: 20px;
			line-height: 1.4 !important;
			border: 1px solid #fff;
			border-radius: 50%;
			color: #fff;
			padding: 0.1rem;
		}
		i {
			font-size: 28px;
			color: #0069d9;
		}
	}
	.notify-items {
		width: 305px;
		text-align: center;
		.DropdownItem {
			.dropdown-item {
				padding: 0px !important;
			}
			h5,
			p {
				padding: 0px 2px !important;
			}
			p {
				// color: rgb(152, 152, 152) !important;
				font-size: 14px;
			}
		}
		.notify-show-more {
			background-color: white;
			text-transform: capitalize;
			border: 1px solid #cb9a51;
		}
		.dropdown-item {
			display: flex;
			//text-align: center;
			padding: 15px;
			//border-radius: 5px;
			//margin: 2px 0;
			.dropdown-item-data {
				h5 {
					color: #0184fe;
					font-size: 15px;
					font-weight: 500;
				}
				p {
					// color: #656565;
					font-size: 14px;
				}
			}
		}
		.dropdown-item:hover,
		.dropdown-item:focus {
			color: #16181b;
			text-decoration: none;
			//background-color: #e9ecef;
			background-color: rgba(23, 119, 246, 0.1);
		}
	}

	.read {
		background-color: #2c302e;
	}

	.not-read {
		background-color: rgba(23, 119, 246, 0.1);
	}
}
.custom-notification {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background-color: #ebebeb;
	display: flex;
	justify-content: center;
	align-items: center;
}
.show > .bell-notifaction .custom-notification {
	background-color: #fff !important;
}
.notifaction-all {
	.dot-read {
		font-size: 15px !important;
	}
	.read {
		background-color: #f2f2f2;
		cursor: pointer !important;
	}
	.not-read {
		background-color: rgba(23, 119, 246, 0.1);
		cursor: pointer !important;
	}
}
.booking-box {
	border: 1px solid #eee;
	margin-top: 10px;
	.booking-header {
		border-bottom: 1px solid #eee;
		margin-bottom: 10px;
	}
	.booking-label {
		font-weight: bold;
		color: #000;
	}
}
.bg-room {
	background-color: #e4e4e4 !important;
	border-radius: 5px;
}
.model-msg {
	width: 75%;
	text-align: center;
}
.per-title {
	border-bottom: 1px solid rgb(207, 205, 205);
}
