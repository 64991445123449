.hotel-box {
	background: #e5e5e5;
	padding: 1rem;
	.head {
		display: flex;
		align-items: center;
		.title {
			font-weight: 700;
			color: #324356;
		}
		.bookable {
			background: #00b437;
			color: #fff;
			padding: 0.1rem 0.5rem;
			border-radius: 3px;
			font-size: 12px;
			overflow: hidden;
			margin: 0 0.5rem;
		}
	}
}

.link-toggle{
	width: 217px !important;
}