.property {
	overflow-x: hidden !important;
	.title-hotel {
		width: 82%;
	}
	h1 {
		font-weight: 600;
		color: #324356;
		font-size: 1.1em;
		text-transform: capitalize;
	}
	.route-block {
		a {
			border-radius: 6px 6px 0 0 !important;
			padding: 0.9rem 2rem;
			display: inline-block;
			color: #b4b4b4;
			background: #fff;
			border: 1px solid #e5e5e5;
			margin-inline-end: 10px;
			font-size: 14px;
			&.done {
				color: #324356 !important;
				font-weight: 500;
				border-top: 3px solid #00b437;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				position: relative;

				&::after {
					position: absolute;
					// font-family: "Font Awesome 5 Brands" !important;
					font-weight: 400;
					content: "\2714";
					color: #00b437;
					left: 15px;
					color: #00b437;
				}
			}
		}
	}
	.table {
		th {
			padding: 0.3rem !important;
			font-size: 14px;
		}
	}
	.is-active {
		font-weight: bold;
		position: relative;
		margin-bottom: -1px;
		&:before {
			// content: "";
			// position: absolute;
			// bottom: -1px;
			// left: 48%;
			// width: 0px;
			// height: 0px;
			// border-left: 5px solid transparent;
			// border-right: 5px solid transparent;
			// border-top: 0;
			// border-bottom: 8px solid #2c302e;
		}
	}

	.content-box {
		/* 		background: #2c302e;
 */
		border: 1px solid #fff;
		border-radius: 4px;
		padding: 1em;
	}

	.header-box {
		background: #eae8e9;
		border-radius: 4px;
		padding: 0.5em 1em;
		font-weight: 500;
	}

	.name-check {
		& > * {
			display: inline-block;
			margin-inline-end: 2em;
		}
	}

	.room {
		table td,
		table {
			padding: 5px;
			border-collapse: separate;
			tr:first-child td:first-child {
				border-top-left-radius: 5px;
			}
			tr:first-child td:last-child {
				border-top-right-radius: 5px;
			}
			tr:last-child td:first-child {
				border-bottom-left-radius: 5px;
			}
			tr:last-child td:last-child {
				border-bottom-right-radius: 5px;
			}
			th {
				min-width: 185px;
				border-bottom: none;
			}
			tr {
				border-radius: 5px;
				.my-2 {
					margin: 0 !important;
				}
			}
		}
		.custom-name {
			color: #1877fa;
			background: #fff;
			border: none;
			padding: 0.625rem 0.625rem 0.3125rem 0.625rem;
			border: 1px solid #eae8e9;
			border-radius: 0.3125rem;
			i {
				padding: 0 0.2rem;
			}
		}
		.delete-name {
			position: absolute;
			right: 10px;
			top: 12px;
			cursor: pointer;
		}
		.table th,
		.table td {
			border-top: none !important;
			padding-bottom: 0.1rem !important;
		}
	}

	.most {
		.amenity {
			padding: 0.4rem;
			padding-left: 0.8rem;

			&.checked {
				background: white;
				padding: 14px;
				// border: 1px dashed #e5e5e5;
			}

			.amenity-check {
				margin-inline-end: 10rem;
			}
		}
	}
	.amenity {
		padding: 0.8rem;
		&.checked {
			background: white;
			padding: 14px;
			border: none;
		}

		.amenity-check {
			margin-inline-end: 10rem;
		}
	}
	.pl-0 {
		[dir="rtl"] & {
			padding-left: 5px !important;
			padding-right: 0 !important;
		}
	}
	.pr-0 {
		[dir="rtl"] & {
			padding-right: 5px !important;
			padding-left: 0 !important;
		}
	}
	.form-check-inline .form-check-input {
		[dir="rtl"] & {
			margin-left: 5px;
		}
	}
}

.gray {
	color: #a7afb7;
}

.gallery {
	margin: 1rem 0 3rem 0;
	.gallery-item {
		// width: 250px;
		border: 1px solid #a7afb7;
		height: 100%;
		img {
			width: 100%;
			height: 280px;
			object-fit: cover;
		}
	}
}

.bg-trash {
	background: #a7afb7;
}

.delete-room {
	width: 20px;
	padding-top: 1rem;
	i {
		cursor: pointer;
	}
}

.upload-images {
	border: 2px dashed #e5e5e5;
	#uploadFile {
		display: block;
		//width: 100%;
		text-transform: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
	}
}

.btn {
	&.cancel {
		font-size: 1rem !important;
		color: #303032 !important;
		background-color: #e9e9ef;
		box-shadow: #e9e9ef 0px 3px 6px;
	}
}

.customModal {
	max-width: 80% !important;
	min-height: 95vh !important;
	img {
		min-height: 600px;
	}
}
.photos-box {
	width: 88% !important;
	overflow-x: hidden !important;
}
