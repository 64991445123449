.is-active-onlineVisa {
	//color: #2c302e;
	color: #00aaeb;
	background-color: #fff !important;
	border-color: #fff !important;
	border: 1px solid #e9e9ef !important;
	font-size: 16px !important;
}

// .is-active-onlineVisa:focus {
// 	color: #00aaeb;
// 	background-color: #fff;
// 	//border-color: #fff !important;
// 	border: 1px solid #e9e9ef !important;
// 	font-size: 16px !important;
// }
.visa-view-list {
	background-color: #fafafa;
}
.text-visa-header {
	color: #313533;
}
.insert-group-checkbox:checked {
	accent-color: #b18849;
	color: white;
}
.list-container {
	max-width: 1300px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.bg-mrz {
	background-color: #979797;
}

.bankConfigrationHeader {
	color: #313533;
	border: 1px solid #d1d6dd;
	border-bottom: none;
	font-weight: bold;
	font-size: 16px;
}
.booking-dropdown-filter {
	position: absolute;
	margin-top: 0.5rem;
	left: 0;
	right: 0;
	z-index: 1001;
	background-color: #fbfaff;
	min-width: 21rem;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.booking-dropdown-colums {
	position: absolute;
	margin-top: 0.5rem;
	left: 0;
	right: 0;
	z-index: 10001;
	background-color: white;
	min-width: 10rem;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.booking-active {
	background-color: #007fdd;
	color: white !important;
}
.booking-close-dropdown {
	height: 100vh;
	z-index: 1000;
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
}
.txt-blue {
	color: #354f5c;
}
.w-40 {
	width: 40%;
}
.txt-colum {
	color: #686d71;
}
.booking-view-header {
	.btn:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}
.bg-bookingView {
	background-color: #2c302e;
	color: white;
	border-bottom: 1px solid white;
}
.border-booking {
	border-bottom: 1px solid #d6d7dd;
}
.SpecialRequests .form-check {
	display: inline-flex;
	width: -moz-fit-content;
	width: fit-content;
	margin: 0;
	min-width: 50%;
}
.react-switch {
	box-shadow: 0 1px 2px grey;
}
.border-reduis-switch {
	border-radius: 3px !important;
}
.viewRequest-active {
	background-color: white !important;
	color: #007fdd !important;
	border-bottom: 0 !important ;
	font-size: 16px !important;
}
.product-build__product-no-data {
	min-height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #eae8e9;
	h4 {
		color: #eae8e9 !important;
	}
}
