.amount{
  width: 250px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  color: #324356;
  .type{
    font-weight: bold;
    margin-bottom: 0px;
    color: #324356;
  }
}
.total{
  background-color: #F2F2F2;
}
.paid{
  background-color: #00B54531;
}
.Remaining{
  background-color: #F1DFE6;
}
.border-box{
  border: 1px solid #D1D6DD;
  border-bottom: none;
  height: 140px;
  width: 100%;
}
.financialTable {
  thead{
    vertical-align:middle !important;
  }
  tr th{
    border: 1px solid #D1D6DD;    
    text-align: center;
    font-size: 14px;
    border-collapse: collapse;
    padding: 0px;
    label{
      font-weight: 900;
    }
  }
  
  tbody tr td{
    border: 1px solid #D1D6DD; 
    text-align: center; 
    font-size: 12px;    
    padding: 6px;
    border-collapse: collapse;

  }
  tbody > .table-active{
    background-color: blue !important;
    }
  
}
.financial-pay{
  .payment-type-row{
    background-color: #eee;
    .payment-type{
      font-size: 25px;
      color: #000;
    }
      
  }

}